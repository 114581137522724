import { api } from '../boot/axios';

const url = '/api/doc';
const name = 'Documentation';

export default {
  namespaced: true,

  actions: {
    async save({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/${payload.id}`, payload);
      } else {
        await api.post(`${url}/`, payload);
      }
    },
    async destroy({ commit }, payload) {
      if (payload.id) {
        await api.delete(`${url}/${payload.id}`, payload);
      } else {
        throw new Error(`${name} - ID not found.`);
      }
    },
    async saveItemDoc({ commit }, payload) {
      await api.post('/api/item-doc/', payload);
    },
    async approve({ commit }, payload) {
      if (payload.mainId) {
        await api.patch(`${url}/validation/approve/${payload.mainId}`, payload);
      } else {
        throw new Error(`${name} - mainId not found.`);
      }
    },
    async reject({ commit }, payload) {
      if (payload.mainId) {
        await api.patch(`${url}/validation/reject/${payload.mainId}`, payload);
      } else {
        throw new Error(`${name} - mainId not found.`);
      }
    },
  },

  mutations: {},

  state: {},

  getters: {},
};
