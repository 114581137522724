import { api } from '../boot/axios';

const url = '/api/report';
const name = 'Report';

export default {
  namespaced: true,

  actions: {
    async load({ commit }, query) {
      const res = await api.get(`${url}/${query ? `?${query}` : ''}`);
      commit('set', { data: res.data.data });
    },
    async loadMine({ commit }) {
      const res = await api.get(`${url}/mine`);
      commit('setMine', { data: res.data.data });
    },
    async loadMyCompany({ commit }, query) {
      const res = await api.get(`${url}/my-company${query ? `?${query}` : ''}`);
      commit('setMyCompany', { data: res.data.data });
    },
    async generate({ commit }, payload) {
      const res = await api.post(`${url}/generate/`, payload);
      return res.data.data;
    },
    async archive({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/archive/${payload.id}`, payload);
      } else {
        throw new Error(`${name} - id not found.`);
      }
    },
    async unarchive({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/unarchive/${payload.id}`, payload);
      } else {
        throw new Error(`${name} - id not found.`);
      }
    },
  },

  mutations: {
    set(state, { data }) {
      state.all = data;
    },
    setMyCompany(state, { data }) {
      state.myCompany = data;
    },
    setMine(state, { data }) {
      state.mine = data;
    },
  },

  state: {
    all: [],
    mine: [],
    myCompany: [],
  },

  getters: {
    get(state) {
      return state.all;
    },
    getMine(state) {
      return state.mine;
    },
    getMyCompany(state) {
      return state.myCompany;
    },
  },
};
