import { api } from '../boot/axios';

const url = '/api/main';
const name = 'Variation data';

export default {
  namespaced: true,


  actions: {
    async search({ commit }, payload) {
      if (
        payload?.countryId !== undefined && payload.countryId !== null
        && payload?.globalMainId !== undefined && payload.globalMainId !== null
      ) {
        const res = await api.get(`${url}/search/${payload.globalMainId}/${payload.countryId}`, payload);
        commit('setSearch', { data: res.data.data, id: `${payload.globalMainId}-${payload.countryId}` });
      } else {
        throw new Error(`${name} - Some ids are missing.`);
      }
    },
    async getByVarCode({ commit }, payload) {
      if (
        payload?.countryId !== undefined && payload.countryId !== null
        && payload?.mainVarCode !== undefined && payload.mainVarCode !== null
      ) {
        const res = await api.get(`${url}/get-by-var-code/${payload.mainVarCode}/${payload.countryId}`, payload);
        commit('setByVarCode', { data: res.data.data, id: `${payload.mainVarCode}-${payload.countryId}` });
      } else {
        throw new Error(`${name} - Some ids are missing.`);
      }
    },
    async save({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/${payload.id}`, payload);
      } else {
        await api.post(`${url}/`, payload);
      }
    },
    async destroy({ commit }, payload) {
      if (payload.id) {
        await api.delete(`${url}/${payload.id}`, payload);
      } else {
        throw new Error(`${name} - ID not found.`);
      }
    },
    async approve({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/validation/approve/${payload.id}`, payload);
      } else {
        throw new Error(`${name} - ID not found.`);
      }
    },
    async reject({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/validation/reject/${payload.id}`, payload);
      } else {
        throw new Error(`${name} - ID not found.`);
      }
    },
    async loadByCountry({ commit }, payload) {
      if (payload.countryId) {
        const res = await api.get(`${url}/load-by-country/${payload.countryId}`, payload);
        commit('setByCountry', { data: res.data.data, countryId: payload.countryId });
      } else {
        throw new Error(`${name} - countryId not found.`);
      }
    },
  },


  mutations: {
    setSearch(state, { data, id }) {
      state.search[id] = data;
    },
    setByVarCode(state, { data, id }) {
      state.byVarCode[id] = data;
    },
    setByCountry(state, { data, countryId }) {
      state.varCodesByCountry[countryId] = data;
    },
  },


  state: {
    all: [],
    search: {},
    byVarCode: {},
    nonValidated: [],
    varCodesByCountry: {},
  },


  getters: {
    getSearch(state) {
      return (id) => state.search[id];
    },
    getByVarCode(state) {
      return (id) => state.byVarCode[id];
    },
    loadByCountry(state) {
      return (id) => state.varCodesByCountry[id];
    },
  },
};
