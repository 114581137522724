import { api } from '../boot/axios';

const url = '/api/product';
const name = 'Product';

export default {
  namespaced: true,

  actions: {
    async index({ commit }, payload) {
      const statusFilter = payload?.statusFilter != null ? `?archived=${payload.statusFilter}` : '';
      const res = await api.get(`${url}/${statusFilter}`);
      commit('setAll', { data: res.data.data });
    },
    async save({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/${payload.id}`, payload);
      } else {
        await api.post(`${url}/`, payload);
      }
    },
    async archive({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/archive/${payload.id}`, { withReports: payload?.withReports });
      } else {
        throw new Error(`${name} - id not found.`);
      }
    },
    async unarchive({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/unarchive/${payload.id}`, payload);
      } else {
        throw new Error(`${name} - id not found.`);
      }
    },
  },

  mutations: {
    setAll(state, { data }) {
      state.all = data;
    },
  },

  state: {
    all: [],
  },

  getters: {
    getAll(state) {
      return state.all;
    },
  },
};
