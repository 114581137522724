import { api } from '../boot/axios';

const url = '/api/item-comment';

export default {
  namespaced: true,

  actions: {
    async save({ commit }, payload) {
      if (payload['itemComment.id']) {
        await api.patch(`${url}/${payload['itemComment.id']}`, payload);
      } else {
        await api.post(`${url}/`, payload);
      }
    },
  },

  mutations: {},

  state: {},

  getters: {},
};
