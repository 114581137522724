import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createApp } from 'vue';
import Toast, { TYPE } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import App from './App.vue';
import router from './router';
import store from './store';


const app = createApp(App);

const options = {
  position: 'bottom-right',
  timeout: 6000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: 'button',
  icon: true,
  rtl: false,
  maxToasts: 5,
  toastDefaults: {
    [TYPE.ERROR]: {
      timeout: 10000,
    },
    [TYPE.WARNING]: {
      timeout: 8000,
    },
  },
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter(t => t.content === toast.content && t.type === toast.type).length !== 0) {
      // Returning false discards the toast
      return false;
    }
    // // You can modify the toast if you want
    return toast;
  },
};

app.use(store)
  .use(router)
  .use(Toast, options)
  .mount('#app');
