import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import { LocalStore } from '../utils/localStoreHelper';
import routes from './routes';



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


router.beforeEach((to, from, next) => {
  const token = LocalStore.getToken();
  const user = LocalStore.getUser();
  const isAuth = token !== null && user !== null && user?.username;

  document.getElementById('app').classList.remove(`page-${from.name}`);
  document.getElementById('app').classList.add(`page-${to.name}`);


  if (!isAuth && to.meta?.requiresAuth) {
    next({ name: 'LoginPage' });
  } else if (isAuth && to.name === 'LoginPage') {
    next({ name: 'HomePage' });
  } else if (isAuth && to.meta?.requiresAuth && to.meta?.ability) {
    if (store.getters['auth/can'](to.meta.ability)) {
      next();
    } else {
      next({ name: 'Error404Page' });
    }
  } else if (to.name) {
    next();
  } else {
    next({ name: 'Error404Page' });
  }
});

export default router;
