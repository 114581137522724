import { useToast } from 'vue-toastification';
import { api } from '../boot/axios';

const url = '/api/stat';
const toast = useToast();
const name = 'Statistics';

export default {
  namespaced: true,


  actions: {
    async nonValidated({ commit }) {
      const res = await api.get(`${url}/non-validated/`);
      commit('setNonValidated', { data: res.data.data });
    },
  },


  mutations: {
    setNonValidated(state, { data }) {
      state.nonValidated = data;
    },
  },


  state: {
    nonValidated: [],
  },


  getters: {
    getNonValidated(state) {
      return state.nonValidated;
    },
  },
};
