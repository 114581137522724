import { api } from '../boot/axios';

const url = '/api/user';
const name = 'User';

export default {
  namespaced: true,


  actions: {
    async load({ commit }, payload) {
      const res = await api.get(`${url}/`, payload);
      commit('setAll', { data: res.data.data });
    },
    async save({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/${payload.id}`, payload);
      } else {
        await api.post(`${url}/`, payload);
      }
    },
    async setRoles({ commit }, payload) {
      if (payload?.id) {
        await api.patch(`${url}/${payload.id}/set-roles`, payload);
      } else {
        throw new Error(`${name} - ID not found.`);
      }
    },
  },


  mutations: {
    setAll(state, { data }) {
      state.all = data;
    },
  },


  state: {
    all: [],
  },


  getters: {
    getAll(state) {
      return state.all;
    },
  },
};
