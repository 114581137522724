<template>
  <div class="white-space-pre-wrap mb-2">
    {{ message }}
  </div>
  <button
    type="button"
    class="btn-component btn btn-secondary full-width"
    @click="reportIssue"
  >
    <AIcon conf="btn" icon="issue" /> Report issue
  </button>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { LocalStore } from '../utils/localStoreHelper';

export default {
  name: 'ToastWithRequestBtn',
  components: {
    AIcon: defineAsyncComponent(() => import('./Icon.vue')),
  },
  props: {
    toastId: { type: Object, required: true },
    message: { type: String, required: true },
    issueType: {
      type: String,
      required: true,
      validator(value) {
        return ['axios', 'frontend'].includes(value);
      },
    },
    apiUrl: { type: String, default: null },
    httpMethod: { type: String, default: undefined },
    extra: { type: Object, default: () => {} },
  },
  emits: ['closeToast'],

  setup(props) {
    function reportIssue() {
      let isServerError = '';
      if (props.issueType === 'axios') {
        isServerError = '&server=true';
      }
      LocalStore.storeLastIssue({
        ...props.extra,
        shownMessage: props.message,
        apiUrl: props.apiUrl,
        httpMethod: props.httpMethod,
      });

      window.open(`/issue-reporter?location=${window.location.href}${isServerError}`, '_blank', 'noreferrer');
    }

    return {
      reportIssue,
    };
  },
};
</script>
