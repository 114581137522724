export const StorageKeys = {
  LOCAL_STORAGE_USER: 'user',
  LOCAL_STORAGE_TOKEN: 'token',
  LOCAL_STORAGE_VERSION: 'version',
  LOCAL_STORAGE_SETTINGS: 'settings',
  LOCAL_STORAGE_DEVHELPER: 'devHelper',
  LOCAL_STORAGE_LAST_ISSUE: 'lastIssue',
};

export class LocalStore {
  static storeToken(token) {
    localStorage.setItem(StorageKeys.LOCAL_STORAGE_TOKEN, token);
  }

  static storeUser(user) {
    localStorage.setItem(StorageKeys.LOCAL_STORAGE_USER, JSON.stringify(user));
  }

  static storeVersion(version) {
    localStorage.setItem(StorageKeys.LOCAL_STORAGE_VERSION, JSON.stringify(version));
  }

  static storeSettings(settings) {
    localStorage.setItem(StorageKeys.LOCAL_STORAGE_SETTINGS, JSON.stringify(settings));
  }

  static storeLastIssue(issue) {
    localStorage.setItem(StorageKeys.LOCAL_STORAGE_LAST_ISSUE, JSON.stringify(issue));
  }

  static getSettings() {
    try {
      const settings = localStorage.getItem(StorageKeys.LOCAL_STORAGE_SETTINGS);
      return settings ? JSON.parse(settings) : {};
    } catch (err) {
      return {};
    }
  }

  static getVersion() {
    try {
      return JSON.parse(localStorage.getItem(StorageKeys.LOCAL_STORAGE_VERSION));
    } catch (err) {
      return null;
    }
  }

  static getToken() {
    return localStorage.getItem(StorageKeys.LOCAL_STORAGE_TOKEN);
  }

  static getUser() {
    try {
      return JSON.parse(localStorage.getItem(StorageKeys.LOCAL_STORAGE_USER));
    } catch (err) {
      return null;
    }
  }

  static getDevHelper() {
    return localStorage.getItem(StorageKeys.LOCAL_STORAGE_DEVHELPER);
  }

  static getLastIssue() {
    try {
      return JSON.parse(localStorage.getItem(StorageKeys.LOCAL_STORAGE_LAST_ISSUE));
    } catch (err) {
      return {};
    }
  }

  static clear() {
    localStorage.removeItem(StorageKeys.LOCAL_STORAGE_USER);
    localStorage.removeItem(StorageKeys.LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(StorageKeys.LOCAL_STORAGE_VERSION);
    localStorage.removeItem(StorageKeys.LOCAL_STORAGE_DEVHELPER);
    localStorage.removeItem(StorageKeys.LOCAL_STORAGE_SETTINGS);
    localStorage.removeItem(StorageKeys.LOCAL_STORAGE_LAST_ISSUE);
  }
}
