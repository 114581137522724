import { api } from '../boot/axios';

const url = '/api/country';
const name = 'Country';

export default {
  namespaced: true,

  actions: {
    async loadValid({ commit }, payload) {
      const res = await api.get(`${url}/valid`, payload);
      commit('setValid', { data: res.data.data });
    },
    async loadValidWithFiles({ commit }, payload) {
      const res = await api.get(`${url}/valid-with-files`, payload);
      commit('setValidWithFiles', { data: res.data.data });
    },
    async load({ commit }, payload) {
      const res = await api.get(`${url}/`, payload);
      commit('setAll', { data: res.data.data });
    },
    async loadAllowed({ commit }, payload) {
      const res = await api.get(`${url}/allowed`, payload);
      commit('setAllowed', { data: res.data.data });
    },
    async save({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/${payload.id}`, payload);
      } else {
        await api.post(`${url}/`, payload);
      }
    },
    async destroy({ commit }, payload) {
      if (payload.id) {
        await api.delete(`${url}/${payload.id}`, payload);
      } else {
        throw new Error(`${name} - ID not found.`);
      }
    },
    async loadNonValidated({ commit }, payload) {
      const res = await api.get(`${url}/validation/non-validated/`, payload);
      commit('setNonValidated', { data: res.data.data });
    },
    async loadNonValidatedSub({ commit }, payload) {
      const res = await api.get(`${url}/validation/non-validated-sub/`, payload);
      commit('setNonValidatedSub', { data: res.data.data });
    },
    async approve({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/validation/approve/${payload.id}`, payload);
      } else {
        throw new Error(`${name} - ID not found.`);
      }
    },
    async reject({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/validation/reject/${payload.id}`, payload);
      } else {
        throw new Error(`${name} - ID not found.`);
      }
    },
    async uploadFile({ commit }, payload) {
      if (payload.countryId) {
        const formData = new FormData();

        formData.append('name', payload.fileName);
        formData.append('document_type', payload.file.type);
        formData.append('file', payload.file);
        if (payload.guideline_name) formData.append('guideline_name', payload.guideline_name);
        if (payload.guideline_date) formData.append('guideline_date', payload.guideline_date);
        if (payload.guideline_version) formData.append('guideline_version', payload.guideline_version);

        const res = await api.post(`${url}/${payload.countryId}/file`, formData, { 'content-type': 'multipart/form-data' });

        return res?.data?.data;
      }
      throw new Error(`${name} - Country id not found.`);
    },
    async getFiles({ commit }, payload) {
      if (payload.countryId) {
        const res = await api.get(`${url}/${payload.countryId}/file`);
        commit('setFiles', { data: res.data.data, id: payload.countryId });
      } else {
        throw new Error(`${name} - CountryID not found.`);
      }
    },
    async editFile({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/file/${payload.id}`, payload);
      } else {
        throw new Error(`${name} - File's id not found.`);
      }
    },
    async destroyFile({ commit }, payload) {
      if (payload.id) {
        await api.delete(`${url}/file/${payload.id}`, payload);
      } else {
        throw new Error(`${name} - File's id not found.`);
      }
    },
    async loadFilledByRegion({ commit }, payload) {
      const res = await api.get(`${url}/by-region/${payload}`);
      commit('setFilledByRegion', { data: res.data.data });
    },
  },

  mutations: {
    setValid(state, { data }) {
      state.valid = data;
    },
    setValidWithFiles(state, { data }) {
      state.validWithFiles = data;
    },
    setAll(state, { data }) {
      state.all = data;
    },
    setAllowed(state, { data }) {
      state.allowed = data;
    },
    setNonValidated(state, { data }) {
      state.nonValidated = data;
    },
    setNonValidatedSub(state, { data }) {
      state.nonValidatedSub = data;
    },
    setFiles(state, { data, id }) {
      state.files[id] = data;
    },
    setFilledByRegion(state, { data }) {
      state.filledByRegion = data;
    },
  },

  state: {
    valid: [],
    validWithFiles: [],
    all: [],
    allowed: [],
    nonValidated: [],
    nonValidatedSub: [],
    files: {},
    filledByRegion: [],
  },

  getters: {
    getValid(state) {
      return state.valid;
    },
    getValidWithFiles(state) {
      return state.validWithFiles;
    },
    getAll(state) {
      return state.all;
    },
    getAllowed(state) {
      return state.allowed;
    },
    getNonValidated(state) {
      return state.nonValidated;
    },
    getNonValidatedSub(state) {
      return state.nonValidatedSub;
    },
    getFiles(state) {
      return (id) => state.files[id];
    },
    getFilledByRegion(state) {
      return state.filledByRegion;
    },
  },
};
