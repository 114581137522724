import { api } from '../boot/axios';

const url = '/api/log';

export default {
  namespaced: true,

  actions: {
    async index({ commit }, payload) {
      const res = await api.get(url);
      commit('setAll', { data: res.data.data });
    },
    async userIssues({ commit }, query) {
      const res = await api.get(`${url}/user-issues${query ? `?${query}` : ''}`);
      commit('setUserIssues', { data: res.data.data });
    },
    async myIssues({ commit }) {
      const res = await api.get(`${url}/my-issues`);
      commit('setMyIssues', { data: res.data.data });
    },
    async save({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/${payload.id}`, payload);
      } else {
        await api.post(`${url}/`, payload);
      }
    },
    async reportIssue({ commit }, payload) {
      await api.post(`${url}/report-issue`, payload);
    },
  },

  mutations: {
    setAll(state, { data }) {
      state.all = data;
    },
    setUserIssues(state, { data }) {
      state.userIssues = data;
    },
    setMyIssues(state, { data }) {
      state.myIssues = data;
    },
  },

  state: {
    all: [],
    userIssues: [],
    myIssues: [],
  },

  getters: {
    getAll(state) {
      return state.all;
    },
    getUserIssues(state) {
      return state.userIssues;
    },
    getMyIssues(state) {
      return state.myIssues;
    },
  },
};
