import { LocalStore } from '../utils/localStoreHelper';

export default {
  namespaced: true,

  actions: {
    store({ commit }, payload) {
      if (Object.keys(payload)) {
        commit('storeSettings', { payload });
      } else {
        throw new Error('Settings - Missing data to set');
      }
    },
  },

  mutations: {
    storeSettings(state, { payload }) {
      Object.assign(state.settings, payload);
      LocalStore.storeSettings(state.settings);
    },
  },

  state: {
    settings: {},
  },

  getters: {
    get(state) {
      return (name) => {
        if (!state.settings[name]) {
          state.settings = LocalStore.getSettings();
        }
        return state.settings[name] ?? null;
      };
    },
  },
};
