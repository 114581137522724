<template>
  <HeadlessLayout v-if="headlessLayout" />
  <AppLayout v-else />
</template>

<script>
import { computed, defineAsyncComponent, onBeforeMount, onErrorCaptured } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import { KnownError, getErrMsg } from './utils/errorHelper';
import { LocalStore } from './utils/localStoreHelper';

export default {
  name: 'App',
  components: {
    AppLayout: defineAsyncComponent(() => import('./views/_layout/AppLayout.vue')),
    HeadlessLayout: defineAsyncComponent(() => import('./views/_layout/HeadlessLayout.vue')),
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const ifDevelop = process.env.NODE_ENV === 'development';
    const toast = useToast();
    const headlessLayout = computed(() => router.currentRoute.value.meta?.headlessLayout);

    async function setEnvVariables() {
      const version = LocalStore.getVersion();
      if (version !== null) {
        await store.dispatch('auth/getVersion');

        if (ifDevelop) {
          // eslint-disable-next-line no-console
          console.log('Version:', { ...store.getters['auth/getVersion'] });
        }
      }
    }

    async function autoLogin() {
      const token = LocalStore.getToken();
      const user = LocalStore.getUser();
      if (token !== null && user !== null && user?.username) {
        // eslint-disable-next-line no-console
        console.log('autoLogin success');
        store.commit('auth/login', {
          data: { user, token },
        });
      } else {
        // eslint-disable-next-line no-console
        console.log('autoLogin failed');
      }
    }

    onBeforeMount(async () => {
      await setEnvVariables();
      await autoLogin();
    });

    onErrorCaptured(async (caughtError) => {
      if (!(caughtError instanceof KnownError)) {
        const msg = `${getErrMsg(caughtError, true)}\nInitiator: App`;
        // eslint-disable-next-line no-console
        console.error(msg);
        toast.error(msg);
        if (caughtError?.response?.status) {
          if ([404].includes(caughtError.response.status)) {
            await router.push({ name: 'Error404Page' });
          } else if ([401, 403, 419].includes(caughtError.response.status)) {
            await router.push({ name: 'LogoutPage' });
          } else {
            // TODO refresh on error?
            // await router.go();
            // await router.push({ name: 'LogoutPage' });
          }
        }
      }
    });

    return {
      headlessLayout,
    };
  },
};
</script>

<style lang="scss">
@import 'assets/app.scss';
</style>
