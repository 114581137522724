export class KnownError extends Error {
  constructor(error) {
    super(error);
    this.response = error?.response;
    this.request = error?.request;
    this.isAxiosError = error?.isAxiosError;
    this.message = error?.message;
    this.stack = error?.stack;
    this.name = 'KnownError';
  }
}

export function getErrMsg(error, details = false) {
  const msg = [];

  if (error?.response?.data?.errors) {
    Object.values(error.response.data.errors).forEach((v) => {
      msg.push(v);
    });
  } else if (error?.response?.data?.data?.errors) {
    // laravel services custom validators
    Object.values(error.response.data.data.errors).forEach((v) => {
      msg.push(v);
    });
  } else if (error?.response?.data?.message) {
    msg.push(error.response.data.message);
  }

  if (error?.response?.data?.error?.message) {
    msg.push(error.response.data.error.message);
  }
  if (error?.response?.data?.messages) {
    Object.values(error.response.data.messages).forEach((v) => {
      msg.push(v);
    });
  }
  if (error.response === undefined && error?.isAxiosError) {
    msg.push('Server error.');
  }
  if (msg.length === 0 && error?.message) {
    msg.push(error.message);
  }
  if (error.length === 0 || msg.length === 0) {
    msg.push('Unknown error.');
  }

  msg.push('');

  if (error?.response?.status || error?.response?.statusText) {
    msg.push(`Status: ${error.response?.statusText} (${error.response?.status})`);
  }
  if (details && error?.response?.config) {
    const { url, method } = error.response.config;
    msg.push(`Endpoint: ${method} - ${url}`);
  }

  return msg.join('\n');
}
