import axios from 'axios';
import { reactive } from 'vue';
import { useToast } from 'vue-toastification';
import ToastWithRequestBtn from '../components/ToastWithRequestBtn.vue';
import { KnownError, getErrMsg } from '../utils/errorHelper';
import { toBool } from '../utils/function';
import { LocalStore } from '../utils/localStoreHelper';

const isDeveloper = toBool(LocalStore.getDevHelper());

const ifDevelop = process.env.NODE_ENV === 'development' || isDeveloper;
axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
const api = axios.create({ baseURL: process.env.VUE_APP_API_URL });
const toast = useToast();
const ongoingQueries = reactive({});

function makeRequest(url) {
  if (ongoingQueries[url]) {
    ongoingQueries[url] += 1;
  } else {
    ongoingQueries[url] = 1;
  }
}

function recieveResponse(url) {
  if (ongoingQueries[url]) {
    if (ongoingQueries[url] === 1) {
      delete ongoingQueries[url];
    } else {
      ongoingQueries[url] -= 1;
    }
  }
}

api.interceptors.request.use(
  (config) => {
    const token = LocalStore.getToken();
    if (token !== undefined && token !== null) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${LocalStore.getToken()}`;
    }
    makeRequest(config.url);
    return config;
  },
  (error) => Promise.reject(error),
);

api.interceptors.response.use(
  async (response) => {
    recieveResponse(response.config.url);
    if (response?.data?.error) {
      let caption = '-';
      try {
        caption = JSON.stringify(response?.data?.error);
      } catch (e) {
        caption = null;
      }
      toast.warning(`${caption}\nInitiator: Axios`);
      // eslint-disable-next-line no-console
      console.warn('Error:', caption);
    }

    if (response?.data?.message) {
      const msg = response.data.message;
      if (msg) {
        if ([299].includes(response?.status)) {
          toast.info(msg);
        } else {
          toast.success(msg);
        }
      }
    }

    return response;
  },
  async (error) => {
    const forwardErrorStatuses = [401, 403, 404, 419];
    recieveResponse(error.config.url);
    const msg = `${getErrMsg(error, ifDevelop)}\nInitiator: Axios`;

    if (error?.response?.status && forwardErrorStatuses.includes(error?.response?.status)) {
      LocalStore.clear();
      toast.error(`${getErrMsg(error)}\nInitiator: Axios`);
      return Promise.reject(error);
    }

    if (error?.response?.status === 499) {
      toast.info('No data to export');
      return Promise.reject(new KnownError(error));
    }

    if (!LocalStore.getToken() || !LocalStore.getUser() || !LocalStore.getUser().username) {
      toast.error(msg);
    } else if (error?.response) {
      const { headers, config, request, ...extra } = error.response;
      ToastWithRequestBtn.props.message = msg;
      ToastWithRequestBtn.props.issueType = 'axios';
      ToastWithRequestBtn.props.extra = extra;
      ToastWithRequestBtn.props.apiUrl = config?.url || null;
      ToastWithRequestBtn.props.httpMethod = config?.method || null;

      toast.error(ToastWithRequestBtn);
    }

    if (ifDevelop) {
      // eslint-disable-next-line no-console
      console.log('Axios error', msg, { error });
    }

    return Promise.reject(new KnownError(error));
  },
);

export {
  api, axios, ongoingQueries,
};

