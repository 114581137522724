import { api } from '../boot/axios';

const url = '/api/user-country';
const name = 'User Country';

export default {
  namespaced: true,

  actions: {
    async loadUserCountries({ commit }, payload) {
      const res = await api.get(`${url}/my-countries-with-regions/`, payload);
      commit('setUserCountries', { data: res.data.data });
    },
    async loadUserCountriesByRegion({ commit }, payload) {
      const res = await api.get(`${url}/my-countries-by-region/${payload}`);
      commit('setUserCountriesByRegion', { data: res.data.data });
    },
    async saveUserCountry({ commit }, payload) {
      await api.post('/api/user-country/', payload);
    },
  },

  mutations: {
    setUserCountries(state, { data }) {
      state.userCountries = data;
    },
    setUserCountriesByRegion(state, { data }) {
      state.userCountriesByRegion = data;
    },
  },

  state: {
    userCountries: [],
    userCountriesByRegion: [],
  },

  getters: {
    getUserCountries(state) {
      return state.userCountries;
    },
    getUserCountriesByRegion(state) {
      return state.userCountriesByRegion;
    },
  },
};
