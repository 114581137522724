import { toBool } from '../utils/function';

const isDeveloper = toBool(require('../utils/localStoreHelper').LocalStore.getDevHelper());

const routes = [
  // public routes
  {
    path: '/',
    name: 'WelcomePage',
    component: () => import('../views/WelcomePage.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: () => import('../views/common-pages/LoginPageEmail.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/logout',
    name: 'LogoutPage',
    component: () => import('../views/common-pages/LogoutPage.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/error404',
    name: 'Error404Page',
    component: () => import('../views/common-pages/Error404Page.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/forgotten-password',
    name: 'ForgotPasswordPage',
    component: () => import('../views/common-pages/ForgotPasswordPage.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/set-new-password/:token',
    name: 'setNewPassword',
    component: () => import('../views/common-pages/SetNewPasswordPage.vue'),
    meta: { requiresAuth: false },
  },

  // private routes
  {
    path: '/admin/company',
    name: 'AdminCompanyPage',
    component: () => import('../views/AdminCompanyPage.vue'),
    meta: { requiresAuth: true, ability: 'admin-companies' },
  },
  {
    path: '/admin/logs',
    name: 'AdminLogPage',
    component: () => import('../views/AdminLogPage.vue'),
    meta: { requiresAuth: true, ability: 'logs' },
  },
  {
    path: '/admin/roles',
    name: 'AdminRolePage',
    component: () => import('../views/AdminRolePage.vue'),
    meta: { requiresAuth: true, ability: 'admin-roles' },
  },
  {
    path: '/admin/users',
    name: 'AdminUserPage',
    component: () => import('../views/AdminUserPage.vue'),
    meta: { requiresAuth: true, ability: 'admin-users' },
  },
  {
    path: '/admin/user-issues',
    name: 'AdminUserIssuesPage',
    component: () => import('../views/AdminUserIssuesPage.vue'),
    meta: { requiresAuth: true, ability: 'user-issues' },
  },
  {
    path: '/core-data',
    name: 'CoreDataPage',
    component: () => import('../views/CoreDataPage.vue'),
    meta: { requiresAuth: true, ability: 'core-data' },
  },
  {
    path: '/file-viewer',
    name: 'FileViewerPage',
    component: () => import('../views/FileViewerPage.vue'),
    meta: { requiresAuth: true, headlessLayout: true },
  },
  {
    path: '/finder',
    name: 'FinderPage',
    component: () => import('../views/FinderPage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/home',
    name: 'HomePage',
    component: () => import('../views/HomePage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/issue-reporter',
    name: 'IssueReporterPage',
    component: () => import('../views/IssueReporterPage.vue'),
    meta: { requiresAuth: true, headlessLayout: true },
  },
  {
    path: '/manage-records',
    name: 'ManageRecordsPage',
    component: () => import('../views/ManageRecordsPage.vue'),
    meta: { requiresAuth: true, ability: 'manage-records' },
  },
  {
    path: '/my-issues',
    name: 'MyIssuesPage',
    component: () => import('../views/MyIssuesPage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/products',
    name: 'ProductsPage',
    component: () => import('../views/ProductsPage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/profile-settings',
    name: 'ProfilePage',
    component: () => import('../views/common-pages/ProfilePage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/rejected-records',
    name: 'RejectedRecordsPage',
    component: () => import('../views/RejectedRecordsPage.vue'),
    meta: { requiresAuth: true, ability: 'rejected-records' },
  },
  {
    path: '/relation-manager',
    name: 'RelationManagerPage',
    component: () => import('../views/RelationManagerPage.vue'),
    meta: { requiresAuth: true, ability: 'relations' },
  },
  {
    path: '/validation',
    name: 'ValidationPage',
    component: () => import('../views/ValidationPage.vue'),
    meta: { requiresAuth: true, ability: 'validation' },
  },
];

if (isDeveloper) {
  routes.push({
    path: '/dev-helper',
    name: 'DevHelperPage',
    component: () => import('../views/dev-helper/DevHelperPage.vue'),
    meta: { requiresAuth: false },
  });
}

export default routes;
