import { api } from '../boot/axios';

const url = '/api/file';
const name = 'File';

export default {
  namespaced: true,

  actions: {
    async loadFile({ commit }, payload) {
      if (payload?.path) {
        return api.get(`${url}/${payload.path}`, { responseType: 'blob' });
      }
      throw new Error(`${name} - path is missing.`);
    },
  },

  mutations: {},

  state: {},

  getters: {},
};
